.threeBody {
    --uib-size: 25px;
    --uib-speed: 0.8s;
    --uib-color: #fff;
    position: relative;
    display: inline-block;
    margin-right: 4px;
    padding-right: 4px;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
  }
  
  .threeBodyDot {
    position: absolute;
    height: 100%;
    width: 30%;
  }
  
  .threeBodyDot:after {
    content: "";
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .threeBodyDot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
  }
  
  .threeBodyDot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
  }
  
  .threeBodyDot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
  }
  
  .threeBodyDot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
      ease-in-out;
  }
  
  .threeBodyDot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
  }
  
  .threeBodyDot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
  }
  
  @keyframes spin78236 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes wobble1 {
    0%,
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
  
    50% {
      transform: translateY(-66%) scale(0.65);
      opacity: 0.8;
    }
  }
  
  @keyframes wobble2 {
    0%,
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
  
    50% {
      transform: translateY(66%) scale(0.65);
      opacity: 0.8;
    }
  }
  

  /* -----Loader 2 ------ */
  .loader2{
    display: grid;
    place-items: center;
  }
  .wrapper {
    width: 200px;
    height: 60px;
    position: relative;
    z-index: 1;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #F2BC00;
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 0.5s alternate infinite ease;
  }
  
  @keyframes circle7124 {
    0% {
      top: 60px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
    }
  
    40% {
      height: 20px;
      border-radius: 50%;
      transform: scaleX(1);
    }
  
    100% {
      top: 0%;
    }
  }
  
  .circle:nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
  }
  
  .circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
  
  .shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: #F2BC00;
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 0.5s alternate infinite ease;
  }
  
  @keyframes shadow046 {
    0% {
      transform: scaleX(1.5);
    }
  
    40% {
      transform: scaleX(1);
      opacity: 0.7;
    }
  
    100% {
      transform: scaleX(0.2);
      opacity: 0.4;
    }
  }
  
  .shadow:nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
  }
  
  .shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
  