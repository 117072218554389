
.verifyContainer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 40px;
}

.circle,
.circle-border {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  background: #8f6;
  transform: scale(1.2);
  animation: circle-anim 400ms ease;
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}
.success::before,
.success::after {
  content: "";
  display: block;
  height: 10px;
  background: #8f6;
  position: absolute;
}

.success::before {
  width: 30px;
  top: 60%;
  left: 18%;
  transform: rotateZ(50deg);
}

.success::after {
  width: 60px;
  top: 47%;
  left: 28%;
  transform: rotateZ(-50deg);
}