/*======================================
7. Home-style-4
 =======================================*/

/* 7.1 wpo-about-section-s4 */

.wpo-about-section-s4 {

    @media(max-width:991px) {
        padding-top: 70px;
    }

    .wpo-about-wrap {
        .wpo-about-img {
            margin-left: -100px;
            padding-right: 50px;

            @media(max-width:1199px) {
                margin-left: -80px;
                padding-right: 30px;
            }

            @media(max-width:991px) {
                margin-left: 0px;
                padding-right: 0px;
            }
        }

        .wpo-about-text {
            .wpo-section-title {
                small {
                    color: $text-color;
                }
            }

            .theme-btn-s4 {
                margin-top: 20px;
            }
        }
    }
}

/* 7.2 wpo-fun-fact-section */

.wpo-fun-fact-section,
.wpo-fun-fact-section-s2 {
    position: relative;
    padding-bottom: 120px;

    @include media-query(767px) {
        padding-bottom: 80px;
    }

    .wpo-fun-fact-grids {
        border: 1px solid $dark-gray;
        padding: 70px 40px;
        position: relative;
        z-index: 1;
        background: url(../../images/noise.png), $white 50% / cover no-repeat;
        border-radius: 20px;

        @media(max-width:767px) {
            padding: 40px 15px 0;
        }

    }

    .wpo-fun-fact-grids .grid {
        width: 25%;
        float: left;

        @include media-query(767px) {
            width: 50%;
            margin-bottom: 50px;
        }
    }

    .grid {

        h3 {
            font-size: 60px;
            font-weight: 700;
            line-height: normal;
            color: $dark-gray;
            margin: 0 0 0.1em;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-family: $heading-font;
            }

            @include media-query(1200px) {
                font-size: 50px;
            }

            @include media-query(991px) {
                font-size: 40px;
            }

            @include media-query(767px) {
                font-size: 35px;
            }
        }
    }

    .grid h3+p {
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        color: $dark-gray;
        font-family: $base-font;
        text-align: center;

        @include media-query(1200px) {
            font-size: 18px;
        }

        @include media-query(991px) {
            font-size: 16px;
        }
    }
}


/* 7.3 wpo-courses-section-s3 */

.wpo-courses-section-s4 {
    background: #F5F5F5;

    .row.wpo-courses-wrap {
        .wpo-courses-item {
            background: none;
            border: 2px solid $text-color;

            .wpo-courses-text {
                .courses-icon {
                    background: #EEEEEE !important;
                    color: $text-color;
                    border: 1px solid $text-color;
                }
            }
        }

        .grid {
            &:hover {
                .wpo-courses-item {
                    background: $dark-gray;

                    .wpo-courses-text {
                        .courses-icon {
                            color: $dark-gray;
                        }
                    }
                }

            }
        }
    }

    .wpo-section-title-s2 small {
        color: #625F71;
    }

    .wpo-section-title-s2 h2 span .shape svg {
        fill: #625F71;
    }

    .shape-1,
    .shape-2,
    .shape-3,
    .shape-4 {
        display: none;
    }
}

/* 7.4 wpo-popular-area-s3 */

.wpo-popular-area-s3 {
    background: $white;

    .theme-btn-s2 {
        background: none;
        border: 1px solid #1A1729;
        color: #1A1729;

        &:before {
            background: #1A1729;
            color: $white;
            transition: all .3s;
        }

        &::after {
            background: #1A1729;
        }

        &:hover {
            background: #1A1729;
            color: $white;

            &:before {
                background: $white;
                color: #1A1729;
            }
        }
    }

    .wpo-section-title-s2 small {
        color: #625F71;
    }

    .wpo-section-title-s2 h2 span .shape svg {
        fill: #625F71;
    }

    .wpo-popular-item {
        box-shadow: none;
        border: 2px solid $text-color;

        .wpo-popular-img {
            img {
                filter: grayscale(100%);
                border: 2px solid $text-color;
            }

            .thumb {
                border: 1px solid $text-color;

                span {
                    background: $dark-gray !important;
                }
            }
        }

        .wpo-popular-content .wpo-popular-text-bottom {
            border-top: 2px solid $text-color;
        }

        .wpo-popular-text-top ul li img {
            filter: grayscale(100%);
        }

        .wpo-popular-content h2 a {
            &:hover {
                color: $dark-gray;
            }
        }
    }
}


/* 7.5 wpo-testimonial-section-s2 */

.wpo-testimonial-section-s2 {
    background: #F5F5F5;

    .shape-1,
    .shape-2 {
        display: none;
    }

    .wpo-section-title-s2 small {
        color: #625F71;
    }

    .wpo-section-title-s2 h2 span .shape svg {
        fill: #625F71;
    }


    .wpo-testimonial-wrap {
        .wpo-testimonial-item .wpo-testimonial-text .fi.quote {
            color: #625F71;
        }

        .slick-prev,
        .slick-next {
            background-color: $white;
            border: 1px solid $dark-gray;

            &:before {
                color: $dark-gray;
            }
            &:hover {
                background-color: $dark-gray;
                &:before {
                    color: $white;
                }
            }
        }
    }

    .wpo-testimonial-text-btm-img img {
        filter: grayscale(100%);
    }
}

/* 7.6 wpo-blog-section-s2 */

.wpo-blog-section-s2 {
    .wpo-section-title-s2 small {
        color: #625F71;
    }

    .wpo-section-title-s2 h2 span .shape svg {
        fill: #625F71;
    }

    .wpo-blog-item {
        .wpo-blog-img {
            img {
                filter: grayscale(100%);
            }
        }

        .wpo-blog-content {
            a.more {
                color: $text-color;
                border-color: $text-color;
            }

            h2 a {
                &:hover {
                    color: $dark-gray
                }
            }

            ul li a:hover {
                color: $dark-gray
            }
        }
    }
}