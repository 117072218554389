@charset "UTF-8";
.gradient-box {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: #FFF;
  background: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 7px;
  padding: 20px;

}
.gradient-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: 7px;
  background: linear-gradient(to right, #9e93e9, #6c82ea);
  
}

.layout{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dasboardCard{
  background-color: var(--dashboardCard-color);
}
.iconCard{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.table{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}