/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-slider,
.static-hero,
.static-hero-s2,
.static-hero-s3,
.static-hero-s4,
.static-hero-s5 {
	height: 920px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(288.29deg, #080A14 38.57%, #10121E 61.69%, #282A34 73.68%, #404354 89.54%, #343643 98.19%);
			opacity: 0.5;
			content: "";
		}

		.slide-content {
			text-align: center;
			padding-bottom: 50px;

			@include media-query(1399px) {
				padding-bottom: 100px;
			}

			@include media-query(991px) {
				padding-bottom: 0px;
			}
		}
	}

	// slider controls
	.swiper-button-prev,
	.swiper-button-next {
		background: transparentize($white, 0.8);
		width: 60px;
		height: 60px;
		line-height: 60px;
		border: 2px solid $white;
		border-radius: 50%;
		opacity: 0.7;
		text-align: center;
		@include transition-time(0.3s);
		display: none;

		&:hover {
			opacity: 0.9;
		}

		@include media-query(767px) {
			display: none;
		}
	}

	.swiper-button-prev {
		left: 25px;

		&:before {
			font-family: "themify";
			content: "\e629";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-next {
		right: 25px;

		&:before {
			font-family: "themify";
			content: "\e628";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		@include media-query(991px) {
			display: none;
		}
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 20px 0 !important;
	}

	.swiper-pagination {
		right: 50px;
		top: 52%;
		left: auto;
		width: unset;
		transform: translateY(-50%);

		@media(max-width:991px) {
			right: 20px;
		}

		@media(max-width:575px) {
			right: 5px;
		}

		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			text-align: center;
			line-height: 12px;
			font-size: 18px;
			color: $white;
			opacity: 1;
			display: block;
			background: none;


			&.swiper-pagination-bullet-active {
				color: $white;
				position: relative;

				&:before {
					position: absolute;
					left: -55px;
					top: 50%;
					width: 50px;
					height: 1px;
					content: "";
					background: $white;
					transition: all .3s;
				}
			}
		}
	}



	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 30px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}

	.hero-shape {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 11;

		svg {
			width: 100%;
		}
	}
}

.wpo-hero-slider,
.static-hero,
.static-hero-s2,
.static-hero-s3,
.static-hero-s4,
.static-hero-s5 {

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 450px;
	}

	.gradient-overlay+.container {
		position: relative;
		z-index: 11;
	}

	.swiper-slide {
		position: relative;
		z-index: 11;
	}

	.wpo-hero-title-top {
		span {
			font-size: 22px;
			color: #e4e4e4;
			font-family: $heading-font;

			@include media-query(767px) {
				font-size: 15px;
			}

		}
	}

	.slide-title-sub {
		h5 {
			font-size: 25px;
			font-weight: 500;
			line-height: 30px;
			text-transform: capitalize;
			color: $theme-primary-color-s2;

			@include media-query(767px) {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}

	.slide-title {
		max-width: 1000px;
		margin: 0 auto;

		@include media-query(1199px) {
			max-width: 750px;
		}


		h2 {
			font-size: 95px;
			font-weight: 500;
			line-height: 95px;
			margin: 10px 0 15px;
			color: #fff;
			text-transform: capitalize;

			@include media-query(1199px) {
				font-size: 70px;
				line-height: 75px;
			}

			@include media-query(991px) {
				font-size: 40px;
				line-height: 55px;
			}

			@include media-query(767px) {
				font-size: 30px;
				line-height: 36px;
			}
		}
	}

	.slide-text {
		max-width: 680px;
		margin: 0 auto;

		@include media-query(767px) {
			max-width: 500px;
		}

		p {
			font-size: 22px;
			color: $white;
			line-height: 35px;
			max-width: 680px;
			margin-bottom: 40px;
			color: #e2e2e2;

			@include media-query(991px) {
				font-size: 18px;
			}

			@include media-query(767px) {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 30px;
			}
		}
	}


	.slide-btns {
		@include media-query(991px) {
			max-width: 400px;
			margin: 0 auto;
		}
	}


	.slide-btns .hero-video-btn {
		margin-left: 40px;

		@include media-query(767px) {
			margin-left: 20px;
		}
	}
}


/*-------------------------------------------
	hero style 1
--------------------------------------------*/
.static-hero,
.static-hero-s2,
.static-hero-s3,
.static-hero-s4,
.static-hero-s5 {
	background: #EEF9F6;
	height: 900px;
	display: flex;
	position: relative;
	z-index: 0;
	overflow: hidden;
	margin: 0 50px;
	border-radius: 20px;

	@media(max-width:1500px) {
		margin: 0 15px;
	}

	@include media-query(1399px) {
		height: 850px;
	}

	@include media-query(1199px) {
		height: 720px;
	}

	@include media-query(991px) {
		height: 100%;

		.hero-container {
			position: relative;
			padding: 100px 0 0;

			.hero-inner {
				position: relative;
			}
		}
	}

	@include media-query(575px) {
		.hero-container {
			padding: 70px 0 0;
		}
	}

	.slide-title {
		padding-top: 10px;

		@include media-query(991px) {
			margin: auto;
		}

		@include media-query(575px) {
			padding-top: 0;
		}

		h2 {
			font-size: 70px;
			font-style: normal;
			font-weight: 700;
			color: $dark-gray;
			z-index: 1;

			@include media-query(1399px) {
				font-size: 55px;
				line-height: 65px;
			}

			@include media-query(1199px) {
				font-size: 50px;
				line-height: 65px;
			}

			@include media-query(1070px) {
				font-size: 40px;
				line-height: 55px;
			}

			@include media-query(575px) {
				font-size: 30px;
				line-height: 47px;
			}


			span {
				position: relative;
				z-index: -1;

				.shape {
					position: absolute;
					top: -10px;
					left: -20px;
					width: 115%;
					z-index: -1;

					svg {
						fill: $theme-primary-color-s2;
					}
				}
			}
		}
	}

	.slide-title-sub {
		@include media-query(991px) {
			margin: auto;
		}

		span {
			font-size: 18px;
			line-height: 23px;
			font-weight: 700;
			color: $theme-primary-color-s3;

			@include media-query(1199px) {
				font-size: 16px;
			}
		}
	}

	.slide-text {
		@include media-query(991px) {
			margin: auto;
		}

		p {
			color: $text-color;

			@include media-query(1199px) {
				font-size: 16px;
			}

			@include media-query(991px) {
				margin: auto;
			}
		}
	}

	.hero-inner .container {
		position: relative;

	}

	.hero-content {
		padding-left: 95px;
		max-width: 740px;

		@include media-query(1600px) {
			padding-left: 85px;
			max-width: 650px;
		}

		@include media-query(1499px) {
			padding-left: 30px;
			max-width: 575px;
		}

		@include media-query(1399px) {
			max-width: 450px;
		}

		@include media-query(1199px) {
			padding-left: 30px;
			max-width: 470px;
		}

		@include media-query(1070px) {
			max-width: 350px;
		}

		@include media-query(991px) {
			max-width: 100%;
			text-align: center;
			padding-left: 0;
		}

	}

	.hero-inner {
		&::before {
			display: none;
		}
	}

	.slide-text p {
		@include media-query(1400px) {
			max-width: 600px;
		}
	}

	.slide-btns {
		display: flex;
		align-items: center;
		margin-top: 40px;

		ul {
			display: flex;
			align-items: center;
			list-style: none;
			padding-left: 20px;


			li {
				.video-btn .btn-wrap {
					display: block;
					width: 55px;
					height: 55px;
					border: 1px solid $theme-primary-color-s3;
					text-align: center;
					line-height: 52px;
					border-radius: 50%;
					color: $theme-primary-color-s3;
					margin-right: 10px;
					background: none
				}

				&.video-text {
					@media(max-width:1070px) {
						display: none;
					}

					a {
						color: $theme-primary-color-s3;
						font-weight: 600;
						font-size: 20px;
						width: 100%;
						height: 100%;
						box-shadow: none;
						border: none;
					}
				}
			}
		}

		@include media-query(991px) {
			margin: auto;
			margin-top: 20px;
			justify-content: center;
		}
	}


	.student-pic {
		position: absolute;
		right: 160px;
		top: -30px;
		z-index: 1;

		@include media-query(1750px) {
			right: 90px;
			max-width: 550px;
			top: 45%;
			transform: translateY(-50%);
		}

		@include media-query(1399px) {
			max-width: 500px;
		}

		@include media-query(1300px) {
			max-width: 450px;
			right: 60px;
		}

		@include media-query(991px) {
			position: relative;
			bottom: 0;
			margin: auto;
			padding-top: 50px;
			right: -30px;
			transform: unset;
		}

		@include media-query(767px) {
			margin-top: 30px;
			right: 0;
		}

		.student-shape {
			@include media-query(767px) {
				position: absolute;
				left: 40px;
				top: 0;
				width: 100%;
			}

			@include media-query(575px) {
				display: none;
			}

			.shape-1,
			.shape-2,
			.shape-3 {
				position: absolute;
				border: 7px solid $theme-primary-color-s2;
				background: $white;
				left: 6px;
				top: 170px;
				width: 600px;
				height: 600px;
				border-radius: 50%;
				z-index: -1;

				@include media-query(1750px) {
					width: 500px;
					height: 500px;
				}

				@include media-query(1399px) {
					width: 400px;
					height: 400px;
				}

				@include media-query(1300px) {
					width: 300px;
					height: 300px;
				}
			}

			.shape-2 {
				background: none;
				left: -45px;
				top: 120px;
				width: 700px;
				height: 700px;

				@include media-query(1750px) {
					width: 600px;
					height: 600px;
				}

				@include media-query(1399px) {
					width: 500px;
					height: 500px;
				}

				@include media-query(1300px) {
					width: 400px;
					height: 400px;
				}
			}

			.shape-3 {
				background: $white;
				left: -90px;
				top: 75px;
				width: 786px;
				height: 786px;
				z-index: -3;

				@include media-query(1750px) {
					width: 700px;
					height: 700px;
				}

				@include media-query(1399px) {
					width: 600px;
					height: 600px;
				}

				@include media-query(1300px) {
					width: 500px;
					height: 500px;
				}

				@include media-query(575px) {
					display: none;
				}
			}
		}

		.student {
			position: absolute;
			right: -11%;
			top: 12%;
			max-width: 312px;
			background: $white;
			display: flex;
			padding: 30px 20px;
			align-items: center;
			text-align: left;
			border-radius: 10px;
			box-shadow: 0px 2px 8px 0px rgba(0, 12, 55, 0.10);

			@include media-query(767px) {
				left: 50%;
				max-width: 290px;
				width: 260px;
				transform: translateX(-50%);
				top: 0;
				padding: 20px 20px;
			}

			h3 {
				margin-bottom: 0;
				font-weight: 700;
				font-size: 26px;
				line-height: 33px;

				@include media-query(767px) {
					font-size: 20px;
					line-height: 28px;
				}
			}

			p {
				margin-bottom: 0;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;

				@include media-query(767px) {
					font-size: 15px;
				}
			}

			.icon {
				i {
					width: 60px;
					height: 60px;
					line-height: 60px;
					background: $theme-primary-color-s3;
					text-align: center;
					border-radius: 50%;
					margin-right: 10px;
					display: block;
					color: $white;
					font-size: 30px;
				}
			}
		}

		.wpo-supporter {
			position: absolute;
			left: -22%;
			bottom: 3%;
			padding: 30px;
			background: $white;
			border-radius: 10px;
			box-shadow: 0px 2px 8px 0px rgba(0, 12, 55, 0.10);
			z-index: 99;
			text-align: center;

			@include media-query(1199px) {
				padding: 15px;
				top: auto;
			}

			@include media-query(767px) {
				padding: 15px;
				top: auto;
				right: auto;
				width: 100%;
				left: 0;
			}

			.wpo-supporter-text {
				text-align: center;

				h3 {
					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					margin-bottom: 0px;
				}

				p {
					margin-bottom: 0;
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 27px;
				}
			}


			.wpo-supporter-slide {
				max-width: 216px;
				list-style: none;
				margin: 0 auto;
				margin-top: 20px;

				.owl-nav {
					display: none;
				}

				li {
					width: 55px;
					height: 55px;

					img {
						width: 55px;
						height: 55px;
						padding: 3px;
						border: 1px solid $border-color;
						border-radius: 50%;
						@include rounded-border(50%);
					}
				}
			}
		}

		.wp-shape-1 {
			position: absolute;
			left: 0;
			top: 90px;
			animation: bounce 3s linear infinite;

			@include media-query(1300px) {
				top: 50px;
				left: -20px;
			}

			@include media-query(575px) {
				top: 120px;
			}
		}

		.wp-shape-2 {
			position: absolute;
			left: -25%;
			top: 50%;
			animation: zoom-in-zoom-out 2s ease infinite;

			@include media-query(1300px) {
				left: -40%;
			}

			@include media-query(767px) {
				left: -25%;
			}

			@include media-query(575px) {
				left: -5%;
			}
		}

		.wp-shape-3 {
			position: absolute;
			right: -15%;
			top: 35%;
			animation: bounce 3s linear infinite;

			@include media-query(1300px) {
				right: -5%;
			}

			@include media-query(767px) {
				right: -15%;
			}

			@include media-query(575px) {
				right: 0;
			}
		}

		.wp-shape-4 {
			position: absolute;
			right: -10%;
			bottom: 15%;
			animation: bounceX 3s linear infinite;
		}
	}

}

/*-------------------------------------------
 static-hero-s2
--------------------------------------------*/
.static-hero-s2,
.static-hero-s3,
.static-hero-s4,
.static-hero-s5 {
	background: #FAFAFF;
	height: 1080px;
	margin: 0;

	@include media-query(1199px) {
		height: 800px;
	}

	@include media-query(991px) {
		height: 100%;
	}

	&:before {
		display: none;
	}

	.student-pic {
		z-index: 1;
		right: 100px;
		top: 45%;
		transform: translateY(-50%);

		@include media-query(1830px) {
			right: 100px;
			max-width: 900px;
		}

		@include media-query(1500px) {
			right: 100px;
			max-width: 800px;
		}

		@include media-query(1399px) {
			right: 70px;
			max-width: 700px;
		}

		@include media-query(1199px) {
			max-width: 450px;
			right: 50px;
		}

		@include media-query(991px) {
			right: 0;
			bottom: 50px;
			padding-top: 0;
			top: auto;
			transform: unset;
			max-width: 100%;
		}

		.inner {
			overflow: hidden;
			height: 860px;
			border-radius: 45%;
			bottom: -14px;
			position: relative;
			border-bottom-left-radius: 44%;
			border-bottom-right-radius: 40%;

			@include media-query(1830px) {
				bottom: -75px;
			}

			@include media-query(1199px) {
				height: 665px;
				bottom: -52px;
			}

			img {
				margin-bottom: -49px;
				position: relative;
				bottom: -25px;
			}
		}

		.back-shape {
			position: absolute;
			left: -50px;
			bottom: -30px;
			width: 691px;
			height: 691px;
			background: $white;
			background-size: cover;
			border-radius: 50%;
			z-index: -1;

			@include media-query(1830px) {
				left: -50px;
				bottom: 72px;
				width: 560px;
				height: 560px;
			}

			@include media-query(1199px) {
				left: -15px;
				bottom: 72px;
				width: 380px;
				height: 380px;
			}

			@include media-query(380px) {
				width: 310px;
				height: 310px;
				left: 50%;
				top: 28%;
				transform: translateX(-50%);
			}

			&::before {
				position: absolute;
				left: 15px;
				top: 15px;
				// background: url(../../images/slider/back-shape.png) no-repeat center center;
				content: "";
				z-index: -11;
				border-radius: 50%;
				width: 661px;
				height: 661px;

				@include media-query(1830px) {
					width: 530px;
					height: 530px;
				}

				@include media-query(1199px) {
					width: 351px;
					height: 351px;
				}

				@include media-query(380px) {
					width: 280px;
					height: 280px;
				}
			}
		}

		.wp-shape-1 {
			top: auto;
			bottom: 10%;
			left: -5%;
		}

		.wp-shape-2 {
			left: -5%;
		}

		.wp-shape-3 {
			right: -8%;
		}

	}

	.hero-content {
		max-width: 730px;
		padding-bottom: 100px;

		@include media-query(1670px) {
			max-width: 694px;
		}

		@include media-query(1399px) {
			padding-left: 40px;
			max-width: 500px;
		}

		@include media-query(1050px) {
			max-width: 360px;
			padding-left: 10px;
		}

		@include media-query(991px) {
			margin: 0 auto;
			max-width: 695px;
			padding: 0;
			padding-top: 50px;
		}

		@include media-query(767px) {
			padding-top: 20px;
		}

		.slide-title {
			h2 {
				span {
					background: $theme-primary-color;
					color: $white;
					display: inline-block;
					padding: 0 10px;
					border-radius: 5px;
					position: relative;
					line-height: 80px;
					margin: 0 10px;

					@include media-query(1199px) {
						line-height: 50px;
					}
				}

				@include media-query(1399px) {
					font-size: 50px;
					line-height: 65px;
				}

				@include media-query(1199px) {
					font-size: 38px;
					line-height: 55px;
				}

				@include media-query(767px) {
					font-size: 30px;
					line-height: 45px;
				}
			}
		}


		.slide-text {
			margin: 0;
			margin-top: 20px;

			@include media-query(767px) {
				margin: 0 auto;
			}

			p {
				color: $text-color;
				max-width: 580px;
				font-size: 20px;
				padding-left: 15px;
				border-left: 3px solid $theme-primary-color-s3;

				@include media-query(1199px) {
					font-size: 16px;
				}

				@include media-query(991px) {
					padding-left: 0;
					border: 0;
				}

				@include media-query(767px) {
					margin: 0 auto;
				}
			}
		}
	}

	.bottom-shape {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;

		svg {
			width: 100%;
		}
	}
}


// static-hero-s3

.static-hero-s3 {
	height: 1000px;
	background: url(../../images/slider/bg.png) no-repeat center top / cover;

	@include media-query(1399px) {
		height: 800px;
	}

	@include media-query(991px) {
		height: 100%;
		padding: 40px 0;
	}

	.hero-container {
		@include media-query(991px) {
			padding-top: 30px;
		}
	}

	.student-pic {
		max-width: 945px;
		top: 52%;

		@media(max-width:1750px) {
			max-width: 845px;
		}

		@include media-query(1600px) {
			right: 60px;
			max-width: 750px;
		}

		@include media-query(1399px) {
			right: 30px;
			max-width: 650px;
		}

		@include media-query(1199px) {
			max-width: 550px;
		}

		@include media-query(991px) {
			right: auto;
			bottom: 0;
		}
	}

	.hero-content {

		@include media-query(1399px) {
			padding-bottom: 0;
		}

		.slide-title {
			h2 {
				span {
					background: transparent;
					color: $theme-primary-color;
					border: 1px solid $theme-primary-color;
					border-radius: 0;

					.s1,
					.s2,
					.s3,
					.s4 {
						position: absolute;
						width: 10px;
						height: 10px;
						border: 1px solid $theme-primary-color;
						background: #EEF9F6;
					}

					.s1 {
						left: -5px;
						top: -5px;
					}

					.s2 {
						left: -5px;
						bottom: -5px;
					}

					.s3 {
						right: -5px;
						top: -5px;
					}

					.s4 {
						right: -5px;
						bottom: -5px;
					}
				}
			}
		}
	}

}


// static-hero-s4

.static-hero-s4 {
	background: #F2F2F2;
	height: 1000px;
	border-radius: 0;

	@include media-query(1399px) {
		height: 800px;
	}

	@include media-query(991px) {
		height: 100%;
		padding: 40px 0;
	}


	.hero-content {
		padding-bottom: 0;

		.slide-text p {
			padding-left: 0;
			border: 0;
		}
	}

	.student-pic {
		top: 52%;

		@media(max-width:1750px) {
			max-width: 900px;
		}

		@include media-query(1600px) {
			right: 60px;
			max-width: 850px;
		}

		@include media-query(1399px) {
			right: 0px;
			max-width: 850px;
		}

		@include media-query(1199px) {
			max-width: 750px;
			right: 0;
		}

		@include media-query(991px) {
			right: auto;
			bottom: 0;
		}
	}
}


// static-hero-s5 

.static-hero-s5 {
	background: #EAF8FA;
	height: 1055px;
	border-radius: 0;


	@include media-query(1399px) {
		height: 800px;
	}

	@include media-query(991px) {
		height: 100%;
		padding: 40px 0;
	}

	.hero-container{
		@include media-query(991px) {
			padding: 80px 0;
		}
	}


	.slide-title {
		padding-top: 0;

		h2 {
			margin-top: 0;
		}
	}

	.hero-content {
		padding-left: 230px;

		@media(max-width:1750px) {
			padding-left: 130px;
		}

		@media(max-width:1650px) {
			padding-left: 30px;
		}

		@media(max-width:991px) {
			padding: 0;
		}

		.slide-text p {
			padding-left: 0;
			border: 0;
			max-width: 484px;
			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: 40px;

			@include media-query(1399px) {
				max-width: 380px;
				font-size: 20px;
			}
		}

		.slide-title {
			h2 {
				span {
					background: transparent;
					color: $theme-primary-color-s2;
				}
			}
		}
	}

	.student-pic {
		z-index: 1;

		@media(max-width:1199px) {
			max-width: 550px;
		}
		@media(max-width:991px) {
			bottom: 0;
			margin-top: 70px;
		}

		.vector-1 {
			position: absolute;
			top: 0;
			left: 30%;
			z-index: -1;
			animation: bounce 3s linear infinite;

			@media(max-width:1199px) {
				top: -8%;
			}

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-2 {
			position: absolute;
			top: 22%;
			left: 6%;
			z-index: -1;
			animation: bounce 5s linear infinite;

			@media(max-width:1199px) {
				left: 2%;
				top: 15%;
			}
			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-3 {
			position: absolute;
			bottom: 22%;
			left: -7%;
			z-index: -1;
			animation: circle 20s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-4 {
			position: absolute;
			bottom: -5%;
			left: 2%;
			z-index: -1;
			animation: bounceX 5s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-5 {
			position: absolute;
			bottom: -12%;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
			animation: bounce 5s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-6 {
			position: absolute;
			bottom: -10%;
			right: 5%;
			transform: translateX(-50%);
			z-index: -1;
			animation: bounceX 3s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-7 {
			position: absolute;
			bottom: 30%;
			right: -13%;
			transform: translateX(-50%);
			z-index: -1;
			animation: bounceX 5s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-8 {
			position: absolute;
			top: 28%;
			right: 12%;
			z-index: -1;
			animation: circle 10s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}

		.vector-9 {
			position: absolute;
			top: 0%;
			right: 12%;
			z-index: -1;
			animation: bounceX 5s linear infinite;

			@media(max-width:575px) {
				max-width: 10%;
			}
		}
	}
}