.ps-menu-root .ps-menuitem-root,
.ps-menu-root .ps-submenu-root {
    list-style: none !important;
}
.ps-sidebar-root .ps-menu-root .ps-menuitem-root {
    padding: 0 0px;
    margin: 0px;
}
.ps-menu-button {
    padding-left: 0px !important; 
}
.col-sm-4 {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
}
.ps-menu-label  {
    padding-left: 7px !important;
     white-space:inherit !important

 
}

.css-1097eso{
    padding-left: 0px !important;
    display: none !important;
}
.css-17j5xqu{
     /* width: 300px !important; */
}
.box {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 0.5px solid #ccc;
}

.box>* {
    flex: 1 1 8rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.lessonName{
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    text-align: left;
    width: 100%;
    padding-inline-end: 30px;

}
.main{
    width: '100%';
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    overflow: auto;
}
.main .lesson{
    padding: 100px;
    overflow: scroll;
}
.lessonHeading{
    font-size: 3rem;
    font-family: 'Roboto', sans-serif;
    color: #272c6c;
    font-weight: 700;
    line-height: 1.2em;
    
}
.section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f1f3f6;
  padding: 20px;
  margin-top: 10px;border-radius: 10px;

}
.section2{
  background: #f1f3f6;
  padding: 20px;
  margin-top: 10px;border-radius: 10px;

}
.inprogressbtn{
    background: #0b84e3;
    padding: 10px 15px;
    font-family: 'Lato', sans-serif;
    font-size: 13.6px;
    color: #fff;
    border-radius: 30px;
    text-align: center;
}
.text1{
    color: #0b84e3;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 15px;
}
.topicSection{
    margin-top: 30px;
    border-radius: 10px;
    background: #f1f3f6;
}

.answertext{
    font-size: 15px;
}
@media (max-width: 768px) {
    .main{
        padding: 0;
    }
    .lessonHeading{
        font-size: 2rem;
    }
    .section{
        padding: 20px;
        display: block;
    }
    .inprogressbtn{
        padding: 5px 10px;
        font-size: 12px;
    }
    .text1{
        font-size: 13px;
    }
    .topicSection{
        margin-top: 20px;
    }
    .main .lesson {
       padding:40px;
            overflow: scroll;
        }
                .answertext {
                    font-size: 12px;
                }
}
.htmlContent * {
    font-size: 13px;
    font-family: "Lato", sans-serif;
}

